import './index.css';


function Home() {
  return (
    <div className="Home" id="Pagetitle" >
        <h1>Flual's Homepage</h1>

    </div>
  );
}

export default Home;
