

export default function NotFound() {
    return (
        <div classname="NotFound">
            <h1>Oops! Es schaut so aus als ob Sie sich verlaufen hättten.</h1>
            <p class="NotFound">Hier gehts zurück:</p>


        </div>
    )
}

